import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Brightness1Icon from '@material-ui/icons/Brightness1';

const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: theme.spacing(2),
    '& > span': {
      margin: theme.spacing(2),
    },
  },
}));

export default function AddCircleColor(props) {
 
  const classes = useStyles();
  const colorType = props.color;

  return (
    <span className={classes.root}>
      {(() => {
        switch (colorType) {
          case "Black": return <Brightness1Icon style={{ color: "#000000" }} />;
          case "Yellow": return <Brightness1Icon style={{ color: "#d39f06" }} />;
          case "Blue":  return <Brightness1Icon style={{ color: "#1973ae" }} />;
          case "White": return <Brightness1Icon style={{ color: "#ffffff" }} />;
          case "Red": return <Brightness1Icon style={{ color: "#d30606" }} />;
          default: return '';
        }
      })()}
    </span>

    // <span className={classes.root}>
    // <Brightness1Icon style={{ color: colorType }} />
    // </span>
    
  );
}