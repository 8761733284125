import { useQuery, gql } from "@apollo/client";

export const useGetAccordion = () => {
  const GET_ACCORDION = gql`
    query GetAccordion {
      pages(where: {title: "Guest Information"}) {
        nodes {
          guestinfoacf {
            rules {
              panelTitle
              panelInfo
            }
          }
        }
      }
    }
  `;
  const { data: accordionData } = useQuery(GET_ACCORDION, {
    fetchPolicy: 'no-cache',
  });
  return accordionData?.pages.nodes[0].guestinfoacf.rules
}