import { gql } from "@apollo/client";

export const GET_GUEST_INFO = gql`
  query GetGuestInfo {
    pages(where: {title: "Guest Information"}) {
      nodes {
        id
        slug
        status
        date
        template {
          templateName
        }
        title
        content
        guestinfoacf {
          pageTitle
          subheadInfo
          scorecardTitle
          welcomeInfo
          welcomeTitle
          backgroundImage {
            sourceUrl
            srcSet
          }
        }
      },
      edges {
        node {
          seo {
            title
            focuskw
            metaDesc
          }
        }
      }
    }
  }
`;

export default GET_GUEST_INFO;