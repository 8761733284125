import { useQuery, gql } from "@apollo/client";

export const useGetScoreCard = () => {
  const GET_SCORECARD = gql`
    query GetScoreCard {
      pages(where: {title: "Guest Information"}) {
        nodes {
          guestinfoacf {
            scorecard {
              teeBoxColor
              hole1
              hole10
              hole11
              hole12
              hole13
              hole14
              hole15
              hole16
              hole17
              hole18
              hole2
              hole3
              hole4
              hole5
              hole6
              hole7
              hole8
              hole9
              holeInfo
              inTotal
              outTotal
              totals
            }
          }
        }
      }
    }
  `;
  const { data: scorecardData } = useQuery(GET_SCORECARD, {
    fetchPolicy: 'no-cache',
  });
  return scorecardData?.pages.nodes[0].guestinfoacf.scorecard
}