import React, { useEffect, useState } from "react";
import { useQuery } from '@apollo/client';
import SEO from '../components/seo/seo';
import Loading from '../components/loading/loading';
import parse from 'html-react-parser';
import Accordion  from '../components/accordion/accordion';
import ScoreCard from '../components/scorecard/scorecard';
import GuestInfoStyle from '../components/styled/guestinfo-style';
import GET_GUEST_INFO from '../graphql/get-guest-info';

export default function Page() {
  const [ guestInfoPage, setGuestInfoPage ] = useState();
  const [ seo, setSEO ] = useState();
  const { loading, data: pageData } = useQuery(GET_GUEST_INFO, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (!loading) {
      setGuestInfoPage(pageData.pages.nodes[0]);
      setSEO(pageData.pages.edges[0].node.seo);
    }
  }, [loading, pageData, guestInfoPage]);

  const heroImage = guestInfoPage?.guestinfoacf.backgroundImage?.sourceUrl;

  return (
    <>
      {loading ? (
        <>
          <Loading />
        </>
      ) :(
        <>
          <SEO
            title={seo?.title || 'Guest Information'}
            description={seo?.description}
          />
          <GuestInfoStyle>
            <div
              style={{ backgroundImage: `url(${heroImage})`}}
              className="background"
            >
              <div className="blue-overlay">
                <div className="container">
                  <div className="row">
                    <div className="col-12 page-title">
                      <h1>{guestInfoPage?.guestinfoacf?.pageTitle}</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <section className="subhead">
              <div className="container">
                <div className="row">
                  <div className="col-12 text-white spacing">
                    {parse(`${guestInfoPage?.guestinfoacf?.subheadInfo}`)}
                  </div>
                </div>
              </div>
            </section>

            <section className="scorecard">
              <div className="container">
                <div className="row">
                  <div className="col-12 pb-2">
                    <h2>{guestInfoPage?.guestinfoacf?.scorecardTitle}</h2>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <ScoreCard />
                  </div>
                </div>
              </div>
            </section>

            <section>
              <div className="container">
                <div className="row">  
                  <div className="col-12 welcome">
                    <h2>
                      {parse(`${guestInfoPage?.guestinfoacf?.welcomeTitle}`)}
                    </h2>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 welcome-message">
                    {parse(`${guestInfoPage?.guestinfoacf?.welcomeInfo}`)}
                  </div>
                </div>
              </div>
            </section>

            <section>
              <div className="container pb-5">
                <div className="row">
                  <div className="col-12">
                    <Accordion />
                  </div>
                </div>
              </div>
            </section>

          </GuestInfoStyle>
        </>
      )}
    </>
  )
}