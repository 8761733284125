import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styled from "styled-components";
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/AddCircle';
import CollapseMoreIcon from '@material-ui/icons/RemoveCircle';
import { useGetAccordion } from './use-get-accordion'
import parse from 'html-react-parser'; 


const header = {
  fontSize: '1.063rem',
  fontFamily: 'Copperplate',
  fontWeight: '900',
  textTransform: 'uppercase'
};

const Accordion = withStyles({
  root: {
    borderTop: '1px solid rgba(0, 0, 0, .125)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    padding: '1.5rem 0',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    marginBottom: -1,
    minHeight: 56,
    padding: '1rem 0',
    flexDirection: 'row-reverse',
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '0.75rem 0',
      padding: '0 2rem',
    },
    '&:not(&$expanded)': {
      padding: '0 2rem',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const Icon = styled(props => (
    <div {...props}>
      <ExpandMoreIcon className="open">add_circle</ExpandMoreIcon>
      <CollapseMoreIcon className="closed">remove_circle</CollapseMoreIcon>
    </div>
  ))`
    & > .open {
      display: block;
      border-radius: 50px;
      color: #12284C;
      color: var(--darkblue);
      font-size: 2rem;
    }
    & > .closed {
      display: none;
      border-radius: 50px;
      color: #12284C;
      color: var(--darkblue);
      font-size: 2rem;
    }
    .Mui-expanded & > .closed {
      display: block;
      border-radius: 50px;
      color: #12284C;
      color: var(--darkblue);
      font-size: 2rem;
    }
    .Mui-expanded & > .open {
      display: none;
      border-radius: 50px;
      color: #12284C;
      color: var(--darkblue);
      font-size: 2rem;
    }
  `;

const AccordionDetails = withStyles({
  root: {
    padding: '.2rem 4.4rem 1.5rem',
    color: '#12284C',
    fontFamily: 'OpenSansLight',
    lineHeight: '2rem',
    fontSize: '1.063rem',
  },
  
})(MuiAccordionDetails);

export default function CustomAccordion() {

  const accordion = useGetAccordion()
  const [expanded, setExpanded] = React.useState('panel1');
  
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
      {accordion && accordion.map((accordionPanel, index) => {
        const { panelTitle, panelInfo } = accordionPanel;
        return (

          <Accordion 
          square 
          expanded={expanded === index} 
          key={index}
          onChange={handleChange(index)}
          >

            <AccordionSummary 
            aria-controls="panel1d-content" 
            id="panel1d-header"  
            expandIcon={<Icon />} 
            >
              <Typography style={header}>{panelTitle}</Typography>
            </AccordionSummary>

            <AccordionDetails>
              {parse(`${panelInfo}`)}
            </AccordionDetails>

          </Accordion>
        )
        
      })}

    </div>
  );
}