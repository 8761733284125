import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { useGetScoreCard } from './use-get-scorecard'
import AddCircle from './use-add-circle-color'
import './scorecard-style.scss';

const StyledTableCell = withStyles((theme) => ({
  root: {
    padding: '1rem .4rem',

    '&:first-child': {
      paddingLeft: '1.5rem',
    },

    '&:last-child': {
      paddingRight: '1.5rem',
      textAlign: 'right'
    },

  },
  head: {
    backgroundColor: '#E3E3E3',
    fontFamily: 'CopperplateBold',
    fontSize: '.8rem',
    color: '#12284C',
    borderWidth: '0 0 0 1px',
    border: 'solid',
    borderColor: '#979FAC',
    textAlign: 'center',

    '&:last-child': {
      borderWidth: '0 0 0 1px',
      border: 'solid',
      borderColor: '#979FAC',
      paddingRight: '1.5rem',
      textAlign: 'right'
    },

    '&:first-child': {
      border: 'none',
    },

  },
  body: {
    fontSize: '.8rem',
    textAlign: 'center',
    borderRight: '1px solid #979FAC',
    color: '#12284C',

    '&:first-child': {
      textAlign: 'left',
    },

    '&:last-child': {
      border: 'none',
      paddingRight: '1.5rem',
      textAlign: 'right'
    },

  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    backgroundColor: '#E3E3E3',
    '&:nth-of-type(odd)': {
      backgroundColor: '#CDCDCD',
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 960,
    borderRadius: '25px',
    paddingBottom: 0,
  },
});

export default function ScoreCard() {

  const scorecard = useGetScoreCard()
  const classes = useStyles();

  return (
    <>
      <TableContainer className="table-shadow">
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell className="text-left">HOLE</StyledTableCell>
              <StyledTableCell>1</StyledTableCell>
              <StyledTableCell>2</StyledTableCell>
              <StyledTableCell>3</StyledTableCell>
              <StyledTableCell>4</StyledTableCell>
              <StyledTableCell>5</StyledTableCell>
              <StyledTableCell>6</StyledTableCell>
              <StyledTableCell>7</StyledTableCell>
              <StyledTableCell>8</StyledTableCell>
              <StyledTableCell>9</StyledTableCell>
              <StyledTableCell>OUT</StyledTableCell>
              <StyledTableCell>10</StyledTableCell>
              <StyledTableCell>11</StyledTableCell>
              <StyledTableCell>12</StyledTableCell>
              <StyledTableCell>13</StyledTableCell>
              <StyledTableCell>14</StyledTableCell>
              <StyledTableCell>15</StyledTableCell>
              <StyledTableCell>16</StyledTableCell>
              <StyledTableCell>17</StyledTableCell>
              <StyledTableCell>18</StyledTableCell>
              <StyledTableCell>IN</StyledTableCell>
              <StyledTableCell>TOTALS</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {scorecard && scorecard.map((scorecardTable, index) => {
              const { 
                holeInfo,
                hole1, 
                hole2, 
                hole3, 
                hole4, 
                hole5, 
                hole6, 
                hole7, 
                hole8, 
                hole9,
                outTotal, 
                hole10, 
                hole11, 
                hole12, 
                hole13, 
                hole14, 
                hole15, 
                hole16, 
                hole17, 
                hole18,
                inTotal,
                totals,
                teeBoxColor,
              } = scorecardTable;
              return (
              <StyledTableRow key={index}>
                <StyledTableCell component="td" scope="row">
                  {teeBoxColor === null ? '' : (
                    <AddCircle color={teeBoxColor}/>
                  )}
                
                  {holeInfo === 'PAR' ? (
                    <span style={{fontFamily: 'CopperplateBold'}}>{holeInfo}</span>
                  ) : (
                    <span>{holeInfo}</span>
                  )}
                </StyledTableCell>
                <StyledTableCell component="td" scope="row">
                  {hole1}
                </StyledTableCell>
                <StyledTableCell component="td" scope="row">
                  {hole2}
                </StyledTableCell>
                <StyledTableCell component="td" scope="row">
                  {hole3}
                </StyledTableCell>
                <StyledTableCell component="td" scope="row">
                  {hole4}
                </StyledTableCell>
                <StyledTableCell component="td" scope="row">
                  {hole5}
                </StyledTableCell>
                <StyledTableCell component="td" scope="row">
                  {hole6}
                </StyledTableCell>
                <StyledTableCell component="td" scope="row">
                  {hole7}
                </StyledTableCell>
                <StyledTableCell component="td" scope="row">
                  {hole8}
                </StyledTableCell>
                <StyledTableCell component="td" scope="row">
                  {hole9}
                </StyledTableCell>
                <StyledTableCell component="td" scope="row">
                  {outTotal}
                </StyledTableCell>
                <StyledTableCell component="td" scope="row">
                  {hole10}
                </StyledTableCell>
                <StyledTableCell component="td" scope="row">
                  {hole11}
                </StyledTableCell>
                <StyledTableCell component="td" scope="row">
                  {hole12}
                </StyledTableCell>
                <StyledTableCell component="td" scope="row">
                  {hole13}
                </StyledTableCell>
                <StyledTableCell component="td" scope="row">
                  {hole14}
                </StyledTableCell>
                <StyledTableCell component="td" scope="row">
                  {hole15}
                </StyledTableCell>
                <StyledTableCell component="td" scope="row">
                  {hole16}
                </StyledTableCell>
                <StyledTableCell component="td" scope="row">
                  {hole17}
                </StyledTableCell>
                <StyledTableCell component="td" scope="row">
                  {hole18}
                </StyledTableCell>
                <StyledTableCell component="td" scope="row">
                  {inTotal}
                </StyledTableCell>
                <StyledTableCell component="td" scope="row">
                  {totals}
                </StyledTableCell>
              </StyledTableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}